<template>
  <div>
    <!-- 公共头部 -->
    <Header title=""></Header>

    <!-- <div class="section1">
      <router-link to="/personal_regist" replace class="item active"
        >活动报名</router-link
      >
      <router-link to="/team_regist" replace class="item">碳排放岗位证报名</router-link>
    </div> -->

    <div class="section2">
      <div class="box">
        <div class="cate-title">基本信息</div>
        <div class="form-box">
          <wd-cell-group class="group" border>
            <wd-input
              :disabled="UserName"
              label="姓名"
              label-width="100px"
              placeholder="请输入您的姓名"
              align-right
              v-model="registData.name"
            />
            <!-- <wd-picker label="性别" label-width="100px" placeholder="请选择性别" align-right v-model="registData.sex" :columns="['男','女']" /> -->
            <div class="sex">
              <div>
                <div class="lable">性别</div>
              </div>
              <div class="select_sex">
                <wd-radio-group
                  shape="dot"
                  v-model="registData.sex"
                  :disabled="UserSex"
                  inline
                  ><wd-radio value="男">男</wd-radio
                  ><wd-radio value="女">女</wd-radio></wd-radio-group
                >
              </div>
            </div>

            <wd-input
              v-if="!flage"
              label="身份证号"
              label-width="100px"
              placeholder="请填写身份证号"
              align-right
              v-model="registData.cardid"
            />
            <wd-input
              v-if="flage"
              disabled
              label="身份证号"
              label-width="100px"
              placeholder="请填写身份证号"
              align-right
              v-model="registData.cardid"
            />
            <wd-picker
              label="学历"
              label-width="100px"
              placeholder="请选择学历"
              align-right
              v-model="registData.edu"
              :columns="eduList"
            />
            <wd-input
              label="单位"
              label-width="100px"
              placeholder="请填写单位名称"
              align-right
              v-model="registData.company"
            />
            <wd-input
              v-if="!mflage"
              label="联系方式"
              label-width="100px"
              placeholder="请填写您的手机号"
              align-right
              v-model="registData.tel"
            />
            <wd-input
              v-if="mflage"
              disabled
              label="联系方式"
              label-width="100px"
              placeholder="请填写您的手机号"
              align-right
              v-model="registData.tel"
            />
          
          </wd-cell-group>
        </div>
      </div>

      <div class="box">
        <div class="cate-title">项目列表</div>
        <div class="form-box">
          <wd-cell-group class="group" border>
        

            <!-- 选择工种类型-->
            <div class="selectWork dialog_wrap" >
              <div @click="selectWork">
                <wd-cell title="培训项目" :value="workName" />
              </div>
              <!-- 弹框 -->
              <van-dialog
                v-model="worklStatus"
                title="选择培训项目"
                
                @confirm="gethave(registData.yi_id)"
                @cancel="escWorkAttrSelect"
              >
                <wd-radio-group v-model="registData.yi_id">
                  <div class="school_list">
                    <wd-radio
                      :value="item.id"
                      shape="dot"
                      v-for="(item, index) in schoolSubject"
                      :key="index"
                      >{{ item.title }}</wd-radio
                    >
                  </div>
                </wd-radio-group>
              </van-dialog>
            </div>
            <!-- 选择工种 -->

            <div class="selectWork dialog_wrap" >
              <div @click="selecattrtWork">
                <wd-cell title="选择工种" :value="workattrName" />
              </div>
              <!-- 弹框 -->
              <van-dialog
                v-model="workattrlStatus"
                title="选择工种"
                
                @confirm="getselecattrtWork(registData.er_id)"
                @cancel="escWorkSelect"
              >
                <wd-radio-group v-model="registData.er_id">
                  <div class="school_list">
                    <wd-radio
                      :value="item.id"
                      shape="dot"
                      v-for="(item, index) in workNames.lists"
                      :key="index"
                    >
                      {{ item.title }}</wd-radio
                    >
                  </div>
                </wd-radio-group>
              </van-dialog>
            </div>
            <!-- 三级弹框 -->
            <!-- 弹框 -->
            <van-dialog
              v-model="teststatus"
              title="选择工种"
              
              @confirm="getThreeData(registData.san_id)"
              @cancel="escWorkSelect"
            >
              <wd-radio-group v-model="registData.san_id">
                <div class="school_list">
                  <wd-radio
                    :value="item.id"
                    shape="dot"
                    v-for="(item, index) in threeData"
                    :key="index"
                  >
                    {{ item.title }}</wd-radio
                  >
                </div>
              </wd-radio-group>
            </van-dialog>
       
            <!-- 复审 -->
            <wd-datetime-picker
              v-show="registData.type == 2"
              type="date"
              v-model="registData.yxq"
              align-right
              label="证件有效期"
            ></wd-datetime-picker>

            <!-- 选择报名途径 -->
            <div
              class="selectbasics dialog_wrap"
             
            >
              <div @click="selectbasics">
                <wd-cell title="报名途径" :value="basicsName" />
              </div>
              <!-- 弹框 -->
              <van-dialog
                v-model="basicsStatus"
                title="选择报名途径"
                @confirm="getbasics(registData.basics)"
                @cancel="escbasiSelect"
              >
                <wd-radio-group v-model="registData.basics">
                  <div class="school_list">
                    <wd-radio
                      :value="item.id"
                      shape="dot"
                      v-for="(item, index) in basicsList"
                      :key="index"
                      >{{ item.title }}</wd-radio
                    >
                  </div>
                </wd-radio-group>
              </van-dialog>
            </div>

            <!-- <wd-picker v-show="registData.er_id" label="领证类型" label-width="100px" placeholder="请选择领证类型" align-right v-model="registData.type" :columns="typeList" value-key="id" label-key="title" />

            <wd-picker v-show="registData.type == 1 && registData.er_id" label="报名途径" label-width="100px" placeholder="请选择报名途径" align-right v-model="registData.basics" :columns="basicsList" value-key="id"
              label-key="title" /> -->

            <wd-cell title="费用" label-width="100px" v-show="priceInfo.money">
              <div class="cell-box">
                <font color="#ff2e2e">¥{{ priceInfo.money }}</font>
              </div>
            </wd-cell>

            <div
              class="singlepage"
              v-show="priceInfo.desc"
              v-html="priceInfo.desc"
            ></div>
          </wd-cell-group>
        </div>
      </div>

      <div class="box" >
        <div class="cate-title">缴费方式</div>
        <div class="form-box"   @click="typetost">
          <wd-cell-group class="group" border>
            <wd-cell
              title="积分抵用"
              label-width="100px"
              v-show="priceInfo.sdkmoney"
            >
              <div
                class="integral-box"
                @click="registData.integral = !registData.integral"
                :class="[registData.integral ? 'right-icon1' : 'right-icon2']"
              >
                <span class="input"
                  >可用{{ priceInfo.integral }}积分抵用{{
                    priceInfo.sdkmoney
                  }}元</span
                >
              </div>
            </wd-cell>
            <wd-cell
              title="实付款"
              label-width="100px"
              v-show="priceInfo.money"
            >
              <div class="cell-box">
                <font color="#ff2e2e"
                  >¥{{ priceInfo.money - priceInfo.dkmoney }}</font
                >
              </div>
            </wd-cell>
            <wd-picker
            :disabled="showtype"
             label="缴费方式"
              label-width="100px"
              placeholder="请选择缴费方式"
              align-right
              v-model="registData.payType"
              :columns="payList"
               value-key="id"
              label-key="title"
            
            />
          </wd-cell-group>
        </div>
      </div>

      <div class="submit-box">
        <div class="submit" @click="submit">确认报名</div>
      </div>
    </div>

    <!-- 公共底部 -->

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// var mobileSelect4;
// import MobileSelect from "mobile-select";
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showtype:true,
      disabled: true,
      mflage: false,
      teststatus: false,
      schoolName: "请选择校区",
      workName: "请选择培训项目",
      workattrName: "请选择工种",
      serName: "请选择领证类型",
      basicsName: "请选择报名途径",
      serStatus: false,
      worklStatus: false,
      basicsStatus: false,
      workattrlStatus: false,
      sertifiStatus: false,
      basicsStu: false,
      activeSchool: 0,
      show: false,
      fieldValue: "",
      cascaderValue: "",
      threeData: "",
      UserSex: false,
      UserName: false,
      UserCompany: false,
      UserWx: false,
      UserEmail: false,
      UserEdu: false,
      fieldNames: {
        text: "title",
        value: "id",
        children: "lists",
      },
      workNames: [],
      schoolStatus: false,
      registData: {
        school_id: "",
        name: "",
        sex: "",
        cardid: "",
        edu: "",
        company: "",
        tel: "",
        weixin: "",
        email: "",
        yxq: "",
        yi_id: "",
        er_id: "",
        san_id: "",
        type: "1",
        basics: "",
        payType: "",
        integral: false,
      },
      flage: false,
      schoolList: [],
      schoolSubject: [],
      priceInfo: {
        money: 0,
        dkmoney: 0,
        desc: "",
        integral: 0,
        sdkmoney: 0,
      },
      typeList: [
        {
          id: 1,
          title: "初领证",
        },
        // {
        //   id: 2,
        //   title: "复审",
        // },
      ],
      basicsList: [
        {
          id: 1,
          title: "已线下报名",
        },
        {
          id: 2,
          title: "未线下报名",
        },
      ],
      newStatus: false,
      triggerTitle: "",
      payList: [
        {
          id: 1,
          title: "线上缴费",
        },
        {
          id: 2,
          title: "暂不缴费",
        },
      ],
    };
  },
  watch: {
    "registData.er_id"() {
      this.getPrice();
    },
    "registData.type"() {
      this.getPrice();
    },
    "registData.basics"() {
      this.getPrice();
    },
    "registData.integral"() {
      this.getPrice();
    },
  },
  computed: {
    inflage(){
      return this.$route.query.flage;
    },
    uid() {
     this.$store.commit('saveUid',this.$route.query.uid)
      return this.$route.query.uid;
    },
    eduList() {
      return this.$store.state.eduList;
    },
  },
  activated() {
 let flage = this.inflage*1;
  let yid = this.$route.query.yi_id?this.$route.query.yi_id:'';
  let eid = this.$route.query.er_id?this.$route.query.er_id:'';
  switch (flage) {
    // 首页跳转过来的
    case 0:
      this.registData.yi_id = yid;
      this.registData.er_id = eid;
      console.log(this.registData,'regis');
      break;
    // 报名页面跳转过来的
    case 1:
      
      break;
  
    default:
      break;
  }
    this.getUserInfo();

  },
  deactivated() {
    this.registData = {
      school_id: "",
      name: "",
      sex: "",
      cardid: "",
      edu: "",
      company: "",
      tel: "",
      weixin: "",
      email: "",
      yxq: new Date(),
      yi_id: "",
      er_id: "",
      san_id: "",
      type: "1",
      basics: "",
      payType: "",
      integral: false,
    };
    this.schoolList = [];
    this.schoolSubject = [];
    this.priceInfo = {
      money: 0,
      dkmoney: 0,
      desc: "",
      integral: 0,
      sdkmoney: 0,
    };
    this.triggerTitle = "";
  },
  methods: {
    checkEmail(e) {},
    test() {
      this.$router({ path: "/pay_status" });
    },

    escSchoolSelect() {
      this.schoolName = "请选择校区";
      this.registData.school_id = "";
      this.schoolSubject = "";
      this.workNames.lists = "";
      this.registData.yi_id = "";
      this.registData.er_id = "";
      
      this.registData.basics = "";
      this.sertifiStatus = false;
      this.basicsStu = false;
     
    },
    escWorkAttrSelect() {
      this.workName = "请选择培训项目";
      this.registData.school_id = "";
      this.registData.yi_id = "";
      this.registData.er_id = "";
      
      this.registData.basics = "";
      this.workNames.lists = "";
      this.sertifiStatus = false;
      this.basicsStu = false;
    },
    escWorkSelect() {
         this.workattrName = "请选择工种";
      this.registData.school_id = "";
      this.registData.yi_id = "";
      this.registData.er_id = "";
     
      this.registData.basics = "";
      this.sertifiStatus = false;
      this.basicsStu = false;
    },
    escSerkSelect() {
      this.serName = "请选择领证类型";
      this.registData.school_id = "";
      this.registData.yi_id = "";
      this.registData.er_id = "";
     
      this.registData.basics = "";
      this.basicsStu = false;
    },
    escbasiSelect() {
      this.basicsName = "请选择报名途径";
      this.registData.basics = "";
      // this.basicsStu = false;
    },
typetost(){
  let data = this.registData;
 this.checkInfo(data).then(res=>{
    if (res) {
     this.showtype = false
    }else{
      this.showtype = true
    }
  })
 
},
    selectSch() {
      this.schoolStatus = true;
    },
    selectWork() {
      this.worklStatus = true;
    },
    selectSer() {
      this.serStatus = true;
    },
    selectbasics() {
      this.basicsStatus = true;
    },
    selecattrtWork() {
      this.workattrlStatus = true;
    },
    // 查询报名价格
    async getPrice() {
      var school_id = this.registData.school_id;
      var yi_id = this.registData.yi_id;
      var er_id = this.registData.er_id;
      var san_id = this.registData.san_id;
      var type = this.registData.type;
      var basics = this.registData.basics;
      var uid = this.uid;
      var integral = this.registData.integral;
      if (!school_id || !yi_id || !er_id || !type || !uid) {
        this.priceInfo = {
          money: 0,
          dkmoney: 0,
          desc: "",
          integral: 0,
          sdkmoney: 0,
        };
        return false;
      }
      if (!basics) {
        this.priceInfo = {
          money: 0,
          dkmoney: 0,
          desc: "",
          integral: 0,
          sdkmoney: 0,
        };
        return false;
      }
      var data = {
        school_id,
        yi_id,
        er_id,
        san_id,
        type,
        basics,
        uid,
        integral,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/order/orderMoney", data);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "个人报名 查询价格：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.priceInfo = res.data;
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 获取校区下的工种列表
    async getSchoolSubject(school_id) {
      this.registData.school_id = school_id;
      if (this.inflage==1) {
       this.triggerTitle = "";
      this.registData.yi_id = "";
      this.registData.er_id = "";
      this.registData.san_id = "";
      }
      this.priceInfo = {
        money: 0,
        dkmoney: 0,
        desc: "",
        integral: 0,
        sdkmoney: 0,
      };

      if (school_id) {
        var data = {
          school_id,
        };
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.get("/school/schoolSubject", {
          params: data,
        });
        this.$toast.close();

        if (this.$store.state.beta) {
          console.log(
            "个人报名 培训项目：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          this.schoolSubject = res.data;
          let y_id = this.registData.yi_id;
          let e_id = this.registData.er_id;
          let listdata =  this.schoolSubject ;
          if (this.inflage==0) {
            for (let i = 0; i < listdata.length; i++) {
              if (listdata[i].id==y_id) {
              this.workName = listdata[i].title;
              this.workNames = listdata[i];
              this.basicsStu = true;
              for (let j = 0; j < listdata[i].lists.length; j++) {
              if (listdata[i].lists[j].id==e_id) {
                 this.workattrName = listdata[i].lists[j].title
               }
                
              }
            }
              
            }
     
          
          }
          if (!this.show) {
            this.show = true;
            var that = this;
         } else {
            // mobileSelect4.updateWheels(this.schoolSubject);
          }
        } else {
          this.schoolSubject = [];
        }
      } else {
        this.schoolSubject = [];
      }
    },

    // 选择工种类型
    gethave(e) {
      let currIndex = e;
      // console.log(this.schoolSubject);
      let index = this.registData.yi_id;
      this.registData.yi_id = e;
      let activeObj = this.schoolSubject.filter((item) => {
        return item.id == currIndex;
      });
      this.workNames = activeObj[0];
      this.workName = activeObj[0].title;
   

    },
    // 选择工种
    getselecattrtWork(e) {
     
      this.sertifiStatus = true;
      this.registData.er_id = e;
   
      let arr = this.workNames.lists;

      let attrwork = arr.filter((item, index) => {
        return item.id == e;
      });
      this.workattrName = attrwork[0].title;
 
      if (attrwork[0].lists) {
        this.teststatus = true;
        this.threeData = attrwork[0].lists;
      }
       this.basicsStu = true;
    },
    // 三级选择
    getThreeData(e) {
     
      let that = this;
      that.registData.san_id = e;
      let threeD = that.threeData.filter((item) => {
        return item.id == e;
      });
      that.workattrName = threeD[0].title;
     
    
    },
    // 选择领证类型
    getSertif(e) {
      let index = e;
      let typeData = this.typeList;
      let typeSelectData = typeData.filter((item, index) => {
        return item.id == e;
      });
      this.serName = typeSelectData[0].title;
      this.basicsStu = true;
    },
    //选择报名途径
    getbasics(e) {
      let index = e;
       this.registData.basics = e;
      let basicData = this.basicsList;
      let basic = basicData.filter((item, index) => {
        return item.id == e;
      });
      
      this.showtype = false
      this.basicsName = basic[0].title;
    },
    // 获取校区列表
    async getSchoolList() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/school/school");
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "个人报名 学校列表：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.schoolList = res.data;
      } else {
        this.schoolList = [];
      }
    },
    // 实时获取用户信息
    async ajaxGetUserInfo() {
      var data = {
        uid: this.uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/user/userInfo", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "个人信息：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        var userInfo = res.data;
        console.log(userInfo);
        this.$store.commit("saveUserInfo", userInfo);
        this.registData.name = userInfo.name;
        this.registData.sex = userInfo.sex;
        this.registData.cardid = userInfo.cardid;
        this.registData.edu = userInfo.edu;
        this.registData.company = userInfo.company;
        this.registData.tel = userInfo.tel;
        this.registData.weixin = userInfo.weixin;
        this.registData.email = userInfo.email;

        userInfo.sex ? (this.UserSex = true) : (this.UserSex = false);
        userInfo.name ? (this.UserName = true) : (this.UserName = false);
        userInfo.company
          ? (this.UserCompany = true)
          : (this.UserCompany = false);
        userInfo.weixin ? (this.UserWx = true) : (this.UserWx = false);
        userInfo.email ? (this.UserEmail = true) : (this.UserEmail = false);
        userInfo.edu ? (this.UserEdu = true) : (this.UserEdu = false);
        userInfo.cardid ? (this.flage = true) : (this.flage = false);
        userInfo.tel ? (this.mflage = true) : (this.mflage = false);
         this.getSchoolSubject(1)
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let uid = this.uid
      if (!uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return;
        }
        		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.ajaxGetUserInfo();
        this.getSchoolList();
       
      }
    },
    
    clearInfo(){
        this.worklStatus = false;
        this.serStatus = false;
        this.basicsStatus = false;
        this.workattrlStatus = false;
        this.basicsStu = false;
        this.workNames = [];
        this.schoolList = [];
        this.schoolSubject = [];
        this.schoolName = "请选择校区";
        this.workName = "请选择培训项目";
        this.workattrName = "请选择工种";
        this.serName = "请选择领证类型";
        this.basicsName = "请选择报名途径";
        this.registData = {
        school_id: "",
        name: "",
        sex: "",
        cardid: "",
        edu: "",
        company: "",
        tel: "",
        weixin: "",
        email: "",
        yxq: new Date(),
        yi_id: "",
        er_id: "",
        san_id: "",
        type: "1",
        basics: "",
        payType: "",
        integral: false,
      };
        this.priceInfo = {
            money: 0,
            dkmoney: 0,
            desc: "",
            integral: 0,
            sdkmoney: 0,
          };
          this.triggerTitle = "";
    },
    checkInfo(registData){
      return new Promise((resolve,reject)=>{
          var telReg = /^1[3456789]\d{9}$/;
     let emailReg = /@/;
         var cardidReg =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (!registData["name"]) {
        this.$messageBox.alert("请先输入姓名", "温馨提示");
        resolve(false)
      } else if (!registData["sex"]) {
        this.$messageBox.alert("请先选择性别", "温馨提示");
        resolve(false)
      } else if (!cardidReg.test(registData["cardid"])) {
        this.$messageBox.alert("请先输入合法的身份证号", "温馨提示");
        resolve(false)
      } else if (!registData["edu"]) {
        this.$messageBox.alert("请先选择学历", "温馨提示");
        resolve(false)
      } else if (!registData["company"]) {
        this.$messageBox.alert("请先输入单位名称", "温馨提示");
        resolve(false)
      } else if (!telReg.test(registData["tel"])) {
        this.$messageBox.alert("请先输入合法的11位手机号", "温馨提示");
        resolve(false)
      } else if (registData["email"] && !emailReg.test(registData["email"])) {
        this.$messageBox.alert("请先输入合法的邮箱", "温馨提示");
        resolve(false)
      } else if (!registData["school_id"]) {
        this.$messageBox.alert("请先选择校区", "温馨提示");
        resolve(false)
      } else if (!registData["yi_id"]) {
        this.$messageBox.alert("请先选择培训项目", "温馨提示");
        resolve(false)
      } else if (!registData["er_id"]) {
        this.$messageBox.alert("请先选择工种", "温馨提示");
        resolve(false)
      } else if (registData["type"] == 1 && !registData["basics"]) {
        this.$messageBox.alert("请先选择报名途径", "温馨提示");
        resolve(false)
      }else {
        resolve(true)
      }
    })
    },
    // 提交订单
    async submit() {
      var registData = this.registData;
      registData["uid"] = this.uid;
      var telReg = /^1[3456789]\d{9}$/;
      let email = this.registData.email;
      // let emailReg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
      let emailReg = /@/;
      var cardidReg =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;

      if (!registData["uid"]) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
							}
      				  });
          return ;
        }
       		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
        return false;
      }  else if (!registData["name"]) {
        this.$messageBox.alert("请输入姓名", "温馨提示");
        return false;
      } else if (!registData["sex"]) {
        this.$messageBox.alert("请选择性别", "温馨提示");
        return false;
      } else if (!cardidReg.test(registData["cardid"])) {
        this.$messageBox.alert("请输入合法的身份证号", "温馨提示");
        return false;
      } else if (!registData["edu"]) {
        this.$messageBox.alert("请选择学历", "温馨提示");
        return false;
      } else if (!registData["company"]) {
        this.$messageBox.alert("请输入单位名称", "温馨提示");
        return false;
      } else if (!telReg.test(registData["tel"])) {
        this.$messageBox.alert("请输入合法的11位手机号", "温馨提示");
        return false;
      } else if (registData["email"] && !emailReg.test(registData["email"])) {
        this.$messageBox.alert("请输入合法的邮箱", "温馨提示");
        return false;
      } else if (!registData["school_id"]) {
        this.$messageBox.alert("请选择校区", "温馨提示");
        return false;
      } else if (!registData["yi_id"]) {
        this.$messageBox.alert("请选择培训项目", "温馨提示");
        return false;
      } else if (!registData["er_id"]) {
        this.$messageBox.alert("请选择工种", "温馨提示");
        return false;
      } else if (registData["type"] == 1 && !registData["basics"]) {
        this.$messageBox.alert("请选择报名途径", "温馨提示");
        return false;
      } else if (!registData["payType"]) {
        this.$messageBox.alert("请选择缴费方式", "温馨提示");
        return false;
      } else {
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.post(
          "/order/addOrder",
          registData
        );
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "个人报名 订单提交：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          console.log(registData,'registData');
          if (registData.payType == 1) {
            var order = res.data.order;
            this.$router.push({ path: "/pay_info", query: { order:order,uid:this.uid  } });
             this.clearInfo()
          } else {
            this.$router.push({path:"/order/list", query: { order:order,uid:this.uid  }});
             this.clearInfo()
          }
         
        } else {
          this.$messageBox.alert(res.data, "温馨提示");
        }
      }
    },
  },
};
</script>

<style scoped>
.section1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section1 .item {
  flex: 1;
  font-size: 0.28rem;
  color: #969897;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
}
.section1 .item.active {
font-size: 0.32rem;
color: #FFFFFF;
position: relative;
background: #86cb08;
}

.van-cell {
  padding: 0 !important;
}
.van-cell::after {
  display: none !important;
}
.van-field__control {
  text-align: right !important;
}
.section2 {
  padding-top: 0.3rem;
  padding-bottom: 0.6rem;
}
.section2 .box {
  margin-top: 0.3rem;
}
.section2 .box:first-child {
  margin-top: 0;
}
.section2 .box .cate-title {
  height: 0.92rem;
  font-size: 0.32rem;
  color: #333333;
  display: flex;
  align-items: center;
  margin: 0 0.3rem;
  font-weight:bold;
  position: relative;
}

.section2 .box .form-box {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section2 .box .form-box .item {
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  border-top: 0.02rem solid #e1e1e1;
}
.section2 .box .form-box .item:first-child {
  border-top: none;
  
}
.section2 .box .form-box .item .title {
  font-size: 0.28rem;
  color: #333333;
}

/* wd-cell */
.section2 .box .form-box .cell-box,
.section2 .box .form-box .integral-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 5rem;
  padding-right: 0rem;
  position: relative;
}
.section2 .box .form-box .cell-box {
  /* background: url(../../assets/images/icon23.png) no-repeat right center;
  background-size: 0.09rem 0.17rem; */
}
.section2 .box .form-box .right-icon1 {
  background: url(../../assets/images/radio_checked.png) no-repeat right center;
  background-size: 0.24rem 0.24rem;
}
.section2 .box .form-box .right-icon2 {
  background: url(../../assets/images/radio_uncheck.png) no-repeat right center;
  background-size: 0.24rem 0.24rem;
}
.section2 .box .form-box .cell-box .hide-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 666;
  font-size: 0;
}
.section2 .box .form-box .cell-box .input,
.section2 .box .form-box .integral-box .input {
  display: block;
  width: 100%;
  max-width: 4.4rem;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #969897;
  text-align: right;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.85);
}
.section2 .box .form-box .cell-box font {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}
.section2 .box .form-box .singlepage {
  padding: 0.24rem 0.3rem 0.3rem;
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.46rem;
}
.section2 .submit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;
}
.section2 .submit-box .submit {
  width: 5.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c90719;
  font-size: 0.3rem;
  color: #ffffff;
  border-radius: 0.5rem;
}
.sex {
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  height: 1rem;
  line-height: 1rem;
  border-bottom: 1px solid #f3f3f3;
}
.select_sex {
  padding-top: 0.3rem;
}
.school_list .wd-radio {
  margin: 0.5rem 0;
  padding: 0 0.3rem;
}
/deep/.school_list .wd-radio .wd-radio__label {
  width: 95%;
}
.dialog_wrap .wd-cell {
  border-bottom: 1px solid #f3f3f3;
}
/deep/ .dialog_wrap .wd-cell .wd-cell__wrapper .wd-cell__right {
  white-space: nowrap !important;
  overflow: hidden;
}
/deep/
  .box
  .form-box
  .wd-cell-group
  .wd-cell-group__body
  .wd-cell
  .wd-cell__right
  .input {
  text-align: start;
}
/deep/
  .form-box
  .wd-cell-group
  .wd-cell-group__body
  .selectWork
  .wd-cell
  .wd-cell__wrapper
  .wd-cell__left
  .wd-cell__title
  .wd-cell__title-inner {
  white-space: nowrap;
}
/deep/ .selectSchool .van-dialog .van-dialog__header {
  width: 95%;
}
</style>